import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";

import {
  SectionContainer,
  SectionContent,
  SectionWrapper
} from "../common/Section";

import { Layout } from "../components/Layout";
import { Head } from "../components/Head";

import { CallToAction } from "../components/CallToAction";
import { PestCard } from "../components/Card";
import { BenefitsCarousel, TestimonialsCarousel } from "../components/Carousel";
import { TermitesFlexbox } from "../components/Flexbox";
import { CommonPestsGrid } from "../components/Grid";
import { EstimateHero } from "../components/Hero";


const EstimatePage: React.FC = () => {
  const data = useStaticQuery(graphql`
    query {
      estimatepage: mdx(
        frontmatter: {
          title: {
            eq: "Estimate"
          }
        }
      ) {
        frontmatter {
          pageHeading
          pageDescription
          termitesImage {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                placeholder: TRACED_SVG
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }

      benefits: allMdx(
        filter: {
          frontmatter: {
            template: {
              eq: "benefit"
            }
          }
        }
        sort: {
          fields: [frontmatter___order]
          order: ASC
        }
      ) {
        edges {
          node {
            frontmatter {
              heading
              description
              icon
            }
          }
        }
      }

      benefits: allMdx(
        filter: {
          frontmatter: {
            template: {
              eq: "benefit"
            }
          }
        }
        sort: {
          fields: [frontmatter___order]
          order: ASC
        }
      ) {
        edges {
          node {
            frontmatter {
              heading
              description
              icon
            }
          }
        }
      }

      pests: allMdx(
        filter: {
          frontmatter: {
            template: {
              eq: "pest"
            }
            featured: {
              eq: true
            }
          }
        }
        sort: {
          fields: [frontmatter___order]
          order: ASC
        }
      ) {
        edges {
          node {
            frontmatter {
              heading
              image {
                childImageSharp {
                  gatsbyImageData(
                    quality: 100
                    placeholder: TRACED_SVG
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
          }
        }
      }

      testimonials: allMdx(
        filter: {
          frontmatter: {
            template: {
              eq: "testimonial"
            }
          }
        }
      ) {
        edges {
          node {
            frontmatter {
              source
              reviewer
              review
            }
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <Head
        title="Estimate"
      />

      <SectionWrapper
        background="linear-gradient(#FBF7F1 0%, #FBF7F1 65%, white 65%, white 100%)"
        padding={{
          base: '120px 0',
          lg: '226px 0 80px'
        }}
      >
        <SectionContainer>
          <EstimateHero
            heading={ data.estimatepage.frontmatter.pageHeading }
            description={ data.estimatepage.frontmatter.pageDescription }
          />
        </SectionContainer>
      </SectionWrapper>

      <SectionWrapper>
        <SectionContainer>
          <BenefitsCarousel
            nodes={ data.benefits.edges }
          />
        </SectionContainer>
      </SectionWrapper>

      <SectionWrapper
        background="linear-gradient(white 0%, white 57%, #F94E1911 57%, #F94E1911 100%)"
        padding="64px 0 57px"
      >
        <SectionContainer>
          <CommonPestsGrid
            heading="Common Pests"
            description={[
              "At Vester, we know our pests, and you can too. Explore our pest library to learn how to identify common pests."
            ]}
          >
            { data.pests.edges.map(( pest: any, _index: number ) => (
              <PestCard
                heading={ pest.node.frontmatter.heading }
                image={ pest.node.frontmatter.image.childImageSharp.gatsbyImageData }
              />
            ))}
          </CommonPestsGrid>
        </SectionContainer>
      </SectionWrapper>

      <SectionWrapper
        background="background.linkwater"
        padding="155px 0"
      >
        <SectionContainer>
          <TermitesFlexbox
            direction="row"
            heading="Complete Termite Extermination"
            description={[
              "Vester Pest Control is the number one choice for San Diego County termite inspections and termite treatments. We have over 20 years of combined experience and have helped over 20,000 homeowners and businesses with their termite infestations and other pest control needs.",
              "Contact us directly to receive your specialized termite and wood-destroying organisms estimate."
            ]}
            image={ data.estimatepage.frontmatter.termitesImage.childImageSharp.gatsbyImageData }
            imageBackground="white"
            imageMaxW={{
              base: '80%',
              sm: '320px',
              md: '100%'
            }}
          />
        </SectionContainer>
      </SectionWrapper>

      <SectionWrapper
        padding="80px 0 46px"
      >
        <SectionContainer>
          <SectionContent
            justifyContent="flex-start"
            alignItems="center"
            textAlign="center"
            heading="Our service and professionals have earned over 3,700+ reviews!"
            headingWidth={{
              base: '100%',
              lg: '700px'
            }}
            headingMargin="0 auto"
            description={[
              "Vester Pest Control has helped thousands of property owners over the past 20 years. Here are what some of our customers have to say."
            ]}
            descriptionWidth={{
              base: '100%',
              sm: '580px'
            }}
          />
        </SectionContainer>
        <TestimonialsCarousel
          nodes={ data.testimonials.edges }
        />
      </SectionWrapper>

      <SectionWrapper
        padding="60px 0"
      >
        <SectionContainer>
          <CallToAction
            heading="Request a Free Quote"
          />
        </SectionContainer>
      </SectionWrapper>
    </Layout>
  )
}


export default EstimatePage;
